import styled, { css, keyframes } from 'styled-components';
import { Row } from '../GridSystem';

export const Left = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  background-color: #fff;
  @media only screen and (max-width: 48em) {
    width: 100%;
    padding: 0;
  }
`;

export const StyledRow = styled(Row)`
  justify-content: center;
  height: 100%;
  @media only screen and (max-width: 48em) {
    padding: unset;
    align-items: center;
    height: 100vh;
  }
  @media screen and (max-width: 48em) and (orientation: landscape) {
    min-height: 100vh;
    height: auto;
    padding: 0 0 50px;
  }
`;

// **************** Right content ***************

export const Right = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  background: url('../static/assets/images/login/bg.png');
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: 48em) {
    display: none;
  }
`;

export const RightContent = styled.div`
  /* position: absolute;
  top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* flex-direction: column;
  justify-content: center;
  border: 1px solid red; */
  position: relative;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  h1 {
    font-weight: 700;
    font-size: 56px;
    line-height: 109%;
    letter-spacing: -0.01em;
    color: #000000;
    width: fit-content;
    font-family: 'Albert Sans';
    font-style: normal;
    margin: auto;
  }
`;

export const Wrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  @media screen and (max-width: 48em) and (orientation: landscape) {
    min-height: 100vh;
    height: auto;
  }
`;
export const StyledImg = styled.img<{
  readonly position?: string;
}>`
  top: 68px;
  display: block;
  object-fit: contain;
  /* width: 165px; */
  height: 34px;
  pointer-events: none;
  @media only screen and (max-width: 48em) {
    position: unset;
    margin-inline: auto;
    max-width: 107px;
    /* margin-bottom: 20px;
    margin-top: -40px; */
  }
  ${({ position }) =>
    position === 'rightHalf' &&
    css`
      width: 172px;
      height: 149px;
      margin: 0;
      @media only screen and (max-width: 64em) {
        width: 130px;
        height: auto;
      }
    `}
`;
export const SignToContinue = styled.h2`
font-weight: 600;
    font-size: 45px;
    line-height: 109%;
    letter-spacing: -0.01em;
    color: #000000;
  margin: 0 0 40px;
  /* font-size: 34px; */
  /* font-weight: 400; */
  /* color: ${({ theme }) => theme.colors.loginPage.text}; */
  /* max-width: 242px; */
  span{
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;
    letter-spacing: -0.01em;
    color: rgba(0, 0, 0, 0.6);
    display: block;
    @media only screen and (max-width: 48em) {
      padding-top: 0.8rem;
      font-size: 14px;
      line-height: 21px;
  }
  }
  @media only screen and (max-width: 48em) {
    font-size: 32px;
    margin-bottom: 0;
    /* max-width: 0px; */
    text-align: center;
    padding-bottom: 2rem;

  }
`;
export const Form = styled.form`
  max-width: 400px;
  @media only screen and (max-width: 48em) {
    max-width: 370px;
    margin: auto;
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.loginPage.labelColor};
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  display: block;
  margin-bottom: 25px;
  position: relative;

  svg {
    position: absolute;
    z-index: 100;
    color: #000000;
    top: 35%;
    opacity: 0.6;
  }

  .lefticon {
    left: 7%;
  }

  .righticon {
    right: 8%;
  }
  .visible {
    color: #0177c0;
  }

  .validation-error svg {
    color: #fb4949;
  }

  .validation-error input {
    border: 1px solid #fb4949;
  }

  input {
    background: transparent;
    border: none;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    padding-inline: 3.5rem;
    margin-block: auto;
    gap: 8px;
    height: 60px;
    border-radius: 48px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    opacity: 1;

    &:focus {
      border: none;
      opacity: 1;
      box-shadow: 0px 2px 2px rgb(0 0 0 / 7%), 0px 24px 44px rgb(0 0 0 / 8%);
    }

    &:focus ~ svg {
      opacity: 1;
    }
  }
  @media only screen and (max-width: 48em) {
    margin-bottom: 16px;
  }
`;
export const Input = styled.input`
  display: block;
  outline: 0;
  width: 100%;
  margin-top: 7px;
  height: 44px;
  border-radius: 4px;
  padding: 0 18px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.loginPage.text};
  border: solid 1px
    ${({ theme }) => `rgba(${theme.colors.loginPage.inputBorderColor}, 0.1)`};
  background-color: ${({ theme }) => theme.colors.loginPage.inputBgColor};
  transition: 0.3s ease border-color;
  position: relative;
  z-index: 2;
  &:focus {
    border-color: ${({ theme }) =>
      `rgba(${theme.colors.loginPage.inputBorderColor}, 0.3)`};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    /* border: solid 1px */
      /* ${({ theme }) =>
        `rgba(${theme.colors.loginPage.inputBorderColor}, 0.1)`} */
    /* -webkit-text-fill-color: ${({ theme }) =>
      theme.colors.loginPage.text}; */
    /* -webkit-box-shadow: 0 0 0px 1000px */
      /* ${({ theme }) => theme.colors.loginPage.inputBgColor} inset, */
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const LoginWrapper = styled.div`
  display: flex;
  padding-block: 1rem;

  button {
    width: 48%;
    margin-block: auto;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 59px;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: rgba(0, 0, 0, 0.6);
    padding-block: 1.9rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #000000;
      color: white;
    }

    @media only screen and (max-width: 48em) {
      &:hover {
        background-color: #0878be;
        box-shadow: unset;
      }
    }
  }

  a {
    margin-block: auto;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.01em;
    color: #000000;
    padding-inline: 15px;

    &:hover {
      color: #0177c0;
    }
  }

  @media only screen and (max-width: 48em) {
    display: block;
    text-align: center;
    padding-top: 0;
    margin-top: 32px;
    button {
      padding: 19px 23px;
      width: 100%;
      background: #e5e5e5;
      border-radius: 10px;
      font-size: 16px;
      line-height: 16px;
    }
    a {
      padding: 19px 23px;
      width: 100%;
      border-radius: 10px;
      margin-top: 5px;
    }
  }
`;

export const Submit = styled.button<{ disabled?: boolean; active?: boolean }>`
  width: 100%;
  outline: 0;
  border: 0;
  height: 50px;
  line-height: 1;
  background: ${({ theme, active }) =>
    active ? theme.colors.blue : 'rgba(0, 0, 0, 0.1)'} !important;
  border-radius: 4px;
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.black} !important;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s ease all;
  margin-top: 10px;
  &:hover {
    /* 0c476c */
    background: ${({ theme, active }) => '#0c476c'} !important;
    box-shadow: ${({ theme }) => theme.colors.loginPage.submitShadow};
  }
  &:active {
    background: ${({ theme }) => theme.colors.loginPage.blueBlack};
    color: ${({ theme }) => 'red'} !important;
  }

  &:disabled {
    background-color: #0177c0;
  }
  svg {
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
`;

const FadeIn = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;
export const ErrMsg = styled.span<{
  readonly authError?: any;
}>`
  line-height: 117%;
  font-size: 16px;
  letter-spacing: -0.01em;
  display: block;
  margin-top: 12px;
  /*color: ${({ theme }) => theme.colors.red};*/
  color: #000000;
  animation: ${FadeIn} 0.3s ease forwards;
  z-index: 1;
  text-transform: none;
  white-space: nowrap;
  svg {
    width:13px;
    height:13px;
    position: relative;
    top: 1px;
    opacity: 1;
  }

  @media only screen and (max-width: 48em) {
    font-size: 14px;
    line-height: 16px;
  }

  ${({ authError }) =>
    authError &&
    css`
      font-size: 16px;
      position: static;
      bottom: auto;
      display: block;
      margin-top: 14px;
      text-align: center;
    `}
`;
export const DontHaveAccount = styled.p`
  bottom: 2px;
  text-align: center;
  font-size: 17px;
  color: #000000;
  font-weight: 400;
  /* color: ${({ theme }) => theme.colors.loginPage.labelColor}; */
  max-width: 400px;
  margin: 20px 0 0;
  text-align: left;
  
  @media only screen and (max-width: 48em) {
    display: block;
    max-width: none;
    position: unset;
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 117%;
    letter-spacing: -0.01em;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
    
    a {
      font-weight: 700;
      color: #0177C0;
    }
  }
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.loginPage.signUpColor};
  display: inline-block;
  margin-left: 5px;
  font-weight: 600;
  &:hover {
    color: ${({ theme }) => theme.colors.loginPage.signUpColor};
  }
`;

export const LeftLoginBox = styled.div`
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  padding-top: 60px;
  padding-bottom: 60px;
  @media only screen and (max-width: 768px) {
    padding-top: 46px;
    padding-bottom: 0px;
    height: 100%;
    max-width: 100%;
    margin-left: unset;
    margin-right: unset;
  }
`;

export const StyledBg = styled.img`
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  /* height: 100%; */
  height: 100%;
  object-position: top left;
`;
export const StyledReach = styled.h1`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  margin: 51px 0 0;
  white-space: nowrap;
  @media only screen and (max-width: 64em) {
    font-size: 26px;
  }
`;

export const Hr = styled.hr`
  display: none;
  @media only screen and (max-width: 48em) {
    display: block;
    position: absolute;
    width: 100%;
    height: 0px;
    left: 0px;
    bottom: 12rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;
