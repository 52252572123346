import styled from 'styled-components';
import { Row, Col, Grid as BaseGrid } from 'react-styled-flexboxgrid';

const Grid = styled(BaseGrid)`
  max-width: 970px;
  margin: auto;
  width: 100%;
  padding: 0px;
  position: relative;

  /*@media (min-width: 75em) and (max-width: 102.5em) {
    width: auto !important;
    max-width: unset !important;
    margin-left: unset !important;
  }

  @media (min-width: 64em) and (max-width: 75em) {
    width: auto !important;
    max-width: unset !important;
    margin-left: unset !important;
  }

  @media (min-width: 48em) and (max-width: 64em) {
    width: auto !important;
    max-width: unset !important;
    margin-left: unset !important;
  }

  @media (max-width: 64em) {
    width: auto !important;
    max-width: unset !important;
    margin-left: unset !important;
  }*/
`;
const Section = styled.div`
  position: unset;
  max-width: 970px;
  margin: auto;
  width: 100%;
`;
export { Grid, Row, Col, Section };
